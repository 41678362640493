<template>
  <div class="page-container">
    <header-tab activeIndex="4"></header-tab>
<!--    <div class="p-t-header">-->
<!--      <div class="p-t-inner">-->
<!--        <img-->
<!--          class="au-title"-->
<!--          v-lazy="require('../../assets/images/title16.png')"-->
<!--        />-->
<!--        <div-->
<!--          class="au-bg-dt"-->
<!--          align="center"-->
<!--        >-->
<!--          <span class="au-head-line"></span>-->
<!--&lt;!&ndash;          <div class="au-head-d1">&ndash;&gt;-->
<!--&lt;!&ndash;            <span>九医云作为一家专注用技术驱动行业变革的创新型企业<br></span>&ndash;&gt;-->
<!--&lt;!&ndash;            <span>依托移动互联网、区块链技术、人工智能以及大数据、物联网应用等技术，提供专业的数字化解决方案<br></span>&ndash;&gt;-->
<!--&lt;!&ndash;            <span>赋能零售药店等专业化服务升级，构建线上线下打通的服务闭环<br></span>&ndash;&gt;-->
<!--&lt;!&ndash;            <span>将优质资源引入下沉市场，让更加优质便捷的健康服务触手可及<br></span>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--          &lt;!&ndash; <div class="au-head-d2">-->
<!--            <img-->
<!--              style="margin-top: -0.2rem;margin-right: 0.3rem"-->
<!--              v-lazy="require('../../assets/images/fh001.png')"-->
<!--            />-->
<!--            <span>打造基于大数据的生态闭环，推动医疗+医药行业的发展升级</span>-->
<!--            <img-->
<!--              style="margin-top: 0.6rem;margin-left: 0.3rem"-->
<!--              v-lazy="require('../../assets/images/fh002.png')"-->
<!--            />-->
<!--          </div> &ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <honor-us></honor-us>-->
<!--    <join-us></join-us>-->
    <contact-us></contact-us>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
export default {
  name: "aboutUs",
  components: {
    "header-tab": () => import("@/components/headerTab"),
    "honor-us": () => import("./components/honor"),
    "join-us": () => import("./components/joinUs"),
    "contact-us": () => import("./components/contactUs"),
    "nav-footer": () => import("@/components/navFooter"),
  },
};
</script>

<style lang="less" scoped>
.p-t-header {
  position: relative;
  width: 100%;
  height: 16rem;
}
.p-t-inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.au-title {
  width: 6rem;
}
.au-bg-dt {
  height: 9.4rem;
  border-top: 1px solid transparent;
  background-image: url("../../assets/images/lunbo4.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.au-head-line {
  display: block;
  width: 1rem;
  height: 0.1rem;
  background: #6aa5ff;
  margin-top: 1.1rem;
}
.au-head-d1 {
  margin-top: 1.1rem;
  text-align: center;
  span {
    display: block;
    font-size: 0.4rem;
    color: #4a4a4a;
    line-height: 2.1;
  }
}
.au-head-d2 {
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;
  img {
    width: 0.5rem;
    height: 0.38rem;
  }
  span {
    font-size: 0.65rem;
    font-weight: 600;
  }
}
</style>